<template>
  <div style="background-color:#fff !important;height:100vh">

    <div style="overflow:auto;height:calc(100vh - 70px)">
      <div 
        style="
          background-color: #d31145;
          height: 200px;
          border-radius: 0px 0px 35px 35px;">
          <div class="d-flex justify-start align-start text-left white--text body-1 pa-5 font-weight-bold">
            <v-icon @click="goback" color="white" class="mr-3">
              mdi-arrow-left
            </v-icon>
            Profil
          </div>
          <div class="d-flex justify-center align-center pt-12">
            <div class="text-center pa-2 cursor-pointer" style="background-color:white;border-radius:50%;position:relative;">
              <image-input v-model="avatar">
                <div
                  slot="activator"
                  class="c-p d-flex align-center justify-center">
                  <v-avatar
                    width="200"
                    height="200"
                    class="grey lighten-3"
                    v-ripple>

                    <div
                      class="px-5"
                      v-if="addImage && avatar.url === undefined">

                      <v-progress-linear
                        color="#d31145"
                        indeterminate
                        rounded
                        height="6"
                        class="mx-4 mt-4"
                        style="width: 150px;">
                      </v-progress-linear>

                      <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                        Mengupload Gambar . . .
                      </p>

                    </div>

                    <v-img
                      v-if="avatar && !addImage"
                      :src="form.image_url"
                      width="200"
                      height="200">
                    </v-img>

                    <v-img
                      v-if="!avatar && addImage === ''"
                      :src="form.image_url"
                      gradient="to top, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10)"
                      width="200"
                      height="200">

                      <v-icon color="white" class="w-100" large>
                        mdi-camera-retake
                      </v-icon>

                    </v-img>

                  </v-avatar>

                  <v-btn
                    fab
                    elevation="1"
                    color="#d31145"
                    class="v-edit-general cursor-pointer">

                    <v-icon color="white" size="30">
                      mdi-camera-plus
                    </v-icon>

                  </v-btn>
                  
                </div>
              </image-input>
            
            </div>
          </div>
      </div>

      <!-- <div class="d-flex justify-center align-center">
        <div class="text-center pa-4" style="background-color:white;border-radius:50%;position:absolute;height:200px;width:200px;">
          <image-input v-model="avatar">
            <div
              slot="activator"
              class="c-p d-flex align-center justify-center"
            >
              <v-avatar
                width="170"
                height="170"
                class="grey lighten-3"
                v-ripple>

                <div
                  class="px-5"
                  v-if="addImage && avatar.url === undefined">

                  <v-progress-linear
                    color="#d31145"
                    indeterminate
                    rounded
                    height="6"
                    class="mx-4 mt-4"
                    style="width: 150px;">
                  </v-progress-linear>

                  <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                    Mengupload Gambar . . .
                  </p>

                </div>

                <v-img
                  v-if="avatar && !addImage"
                  :src="form.image_url"
                  width="170"
                  height="170">
                </v-img>

                <v-img
                  v-if="!avatar && addImage === ''"
                  :src="form.image_url"
                  gradient="to top, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10)"
                  width="170"
                  height="170">

                  <v-icon color="white" class="w-100" large>
                    mdi-camera
                  </v-icon>

                </v-img>

              </v-avatar>

              <v-btn
                fab
                elevation="1"
                color="#d31145"
                class="v-edit-general">

                <v-icon color="white" size="30">
                  mdi-camera-plus
                </v-icon>

              </v-btn>
              
            </div>
          </image-input>
        
        </div>
      </div> -->

      <div class="d-flex justify-center align-center pt-16 mt-16" style="padding: 15px 20px 0px 20px">
        <v-row>

          <v-col cols="12">

            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Nama Lengkap
            </div>

            <v-text-field
              v-model="form.fullname"
              placeholder="Tuliskan disini"
              required
              color="#d31145"
              maxlength="50"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">
              <div class="text-required">
                Wajib
              </div>
              <div style="font-size: 11px;">

                <span style="font-weight: bold">
                  {{ form.fullname.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 50 karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12">

            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Nomor HP
            </div>

            <v-text-field
              v-model="form.mobilephone"
              placeholder="Tuliskan disini"
              required
              type="number"
              color="#d31145"
              maxlength="15"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">
              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">

                <span style="font-weight: bold">
                  {{ form.mobilephone.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 15 Karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12">

            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Alamat
            </div>

            <v-text-field
              v-model="form.address"
              placeholder="Tuliskan disini"
              required
              color="#d31145"
              maxlength="150"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">

              <div class="text-required">
                Optional
              </div>
              <div style="font-size: 11px;">

                <span style="font-weight: bold">
                  {{ form.address.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 150 karakter
                </span>  
              </div>
            </div>
          </v-col>

        </v-row>
      </div>

    </div>

    <div style="position:absolute;bottom:0;width:100%;height:80px" class="d-flex justify-center align-center px-6">
      <v-btn 
        @click="toValidation()"
        :disabled="process.run" 
        block 
        rounded 
        large
        color="#d31145" 
        class="d-flex justify-start text-capitalize white--text">
        <v-icon>
          mdi-check-circle-outline
        </v-icon>

        <span class="body-1"
          style="display:flex;justify-content:center;width:100%">
          Simpan Perubahan
        </span>
      </v-btn>
    </div>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Ubah Data profil
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin mengubah data profil ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="save()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, Simpan Perubahan
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
  data: () => ({
    form: {
      fullname: "",
      image_url: "https://sodapos.com/media/2021/12/image/product-category-20211201-165905-1638352746.jpg",
      mobilephone: "",
      address: ""
    },
    avatar: null,
    addImage: "",
    confirmation: false,
    process: {
      run: false
    }
  }),
  components: {
    ImageInput
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    this.getprofil()
  },
  methods: {
    goback() {
      this.$router.push('/config')
    },
    async getprofil() {
      this.$store.state.overlay.state = true

      let res = await this.$get(`pos/profile`)

        // console.log(res);
      if (res.status == 200) {
        this.$store.state.overlay.state = false
        let profile = res.results.profile
        this.form = {
          fullname: profile.name,
          image_url: profile.image_url,
          mobilephone: profile.mobilephone,
          address: profile.address
        }

        this.avatar = {
          url: profile.image_url,
        };

        this.addImage = profile.image_url !== "" ? false : "";
      }

      this.$store.state.overlay.state = false
    },
    toValidation() {
      if(!this.form.fullname){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama tidak boleh kosong"
      }else if(!this.form.mobilephone){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor HP tidak boleh kosong"
      }else if(this.form.mobilephone.length < 10){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor HP tidak boleh kurang dari 10 karakter"
      }else {
        this.confirmation = true
      }
    },
    async save(){
      this.$store.state.overlay.state = true
      this.confirmation = false
      this.process.run = true

      let res = await this.$post(`pos/profile/update`, this.form)

      if (res.status == 200) {
        this.process.run = false
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        localStorage.setItem('user', JSON.stringify(res.results.data))
      }

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.process.run = false
      this.$store.state.overlay.state = false
      this.$store.state.snackbar.state = true
      this.$store.state.snackbar.content = res.message
    }
  },
}
</script>
<style scoped>
.v-edit-general {
  position: absolute !important;
  bottom: 20px;
  /* right: 160px; */
  z-index: 5;
  margin-left: 120px;
}
</style>